<template>
    <div class="main">
        <div class="main_box" v-loading="loading">
            <div class="main_form">
                <div class="main_form_list">
                    <div class="flex_center main_form_list_item" style="align-items: stretch; line-height: 32px;" v-if="isTabs === 'sectionTitleConfig'">
                        <div class="main_form_list_item_title">选择地区：</div>
                        <div class="flex main_form_list_item_center">
                            <div class="flex main_form_list_item_center_margin">
                                <div :class="item.name === areaItem ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in areaList" :key="item.code" @click="onTime(item)">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_center main_form_list_item" style="align-items: stretch; line-height: 32px;" v-if="isTabs === 'specificationConfig'">
                        <div class="main_form_list_item_title">规范分类：</div>
                        <div class="flex main_form_list_item_center">
                            <div class="flex main_form_list_item_center_margin">
                                <div :class="item.name === childType ? 'main_form_sort_item main_form_sort_item_on' : 'main_form_sort_item'" v-for="(item, index) in areaList1" :key="item.code" @click="onTime(item)">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex_center main_form_list_item">
                        <div class="main_form_list_item_title">关键字：</div>
                        <div class="flex main_form_list_item_center">
                            <el-input v-model="title" placeholder="输入关键字搜索"></el-input>
                            <el-button type="primary" style="margin-left: 40px;" @click="getCecnNewsPage">搜 索</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="dataList.length !== 0">
                <div class="main_total">
                    搜索结果共（<strong>{{total}}</strong>）
                </div>
                <div class="main_table" v-if="isTabs === 'specificationConfig'">
                    <div class="flex_center main_table_item" v-for="(item, index) in dataList" :key="index" @click="onDetails(item)">
                        <div class="main_table_item_l">
                            <p v-html="item.title"></p>
                            <div class="main_table_item_l_time" style="padding-top: 10px;">
                                <span :style="item.childType === '部门规章' ? 'color: #20B84E;' : item.childType === '规范性文件' ? 'color: #ED8606;' : item.childType === '地方政策法规' ? 'color: #5765E3;' : ''">{{item.childType}}</span>
                                <span style="color: #888888;">{{item.time}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_table" v-else>
                    <div class="flex_center main_table_item" v-for="(item, index) in dataList" :key="index" @click="onDetails(item)">
                        <div class="main_table_item_l">
                            <p v-html="item.title"></p>
                        </div>
                        <div class="flex_row_center main_table_item_r">{{item.time}}</div>
                    </div>
                </div>
                <div class="flex main_pagination">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="size"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </template>
            <template v-else>
                <el-empty :image="require('@/assets/images/bjtsy/no_data.png')" :image-size="400"></el-empty>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { urlCecnNewsPage } from '@/api/engineering'
    import EngineeringSearch from "../components/EngineeringSearch";
    import { highlightStringInArray } from '@/utils/public'
    export default {
        name: "quotaList",
        components: { EngineeringSearch },
        data() {
            return {
                title: '',
                areaList: [
                    { name: '全部', code: -1 },
                    { name: '全国', code: -2 }
                ],
                areaList1: [
                    { name: '全部', code: 1 },
                    { name: '法律法规', code: 2 },
                    { name: '部门规章', code: 3 },
                    { name: '规范性文件', code: 4 },
                    { name: '地方法规', code: 5 },
                ],
                areaItem: '全部',
                dataList: [],
                size: 20,
                total: 0,
                currentPage: 1,
                loading: false,
                isTabs: '',
                childType: ''
            }
        },
        computed: {
            ...mapState({
                addressData: state => state.address.addressData
            })
        },
        watch: {
            addressData: {
                handler(val) {
                    this.areaList = this.areaList.concat(val)
                },
                immediate: true,
                deep: true
            }
        },
        mounted() {
            const { type, childType, isTabs } = this.$route.query
            this.isTabs = isTabs
            this.type = type
            this.childType = childType
            this.getCecnNewsPage()
        },
        methods: {
            onDetails(e) {
                switch (this.isTabs) {
                    case 'sectionTitleConfig':
                        window.open(e.fileUrl, '_blank')
                        break
                    default:
                        this.$router.push({path: "/engineering/quota-details", query: { detailId: e.detailId, bid: e.id }});
                        break
                }
            },
            async getCecnNewsPage() {
                this.loading = true
                const param = `type=${this.type}&childType=${this.childType === '全部' ? '' : this.childType}&current=${this.currentPage}&size=${this.size}&title=${this.title}&city=${(this.areaItem === '全部') ? '' : this.areaItem}`
                const res = await urlCecnNewsPage(param)
                const { records, size, total } = res.data
                this.size = size
                this.total = total
                this.dataList = highlightStringInArray({data: records, keys: 'title'}, this.title)
                this.loading = false
            },
            onTime(e) {
                if (this.isTabs === 'sectionTitleConfig') {
                    this.areaItem = e.name
                } else if (this.isTabs === 'specificationConfig') {
                    this.childType = e.name
                }
                this.getCecnNewsPage()
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.getCecnNewsPage()
            }
        }
    }
</script>

<style scoped lang="less">
    .main_form {
        padding: 20px;
        margin-bottom: 20px;
        border: 1px solid #E4EAF2;
        .main_form_list {
            .main_form_list_item {
                padding-bottom: 20px;
                .main_form_list_item_title {
                    padding-right: 10px;
                    font-size: 16px;
                    color: #4E4E4E;
                }
                .main_form_list_item_center {
                    flex: 1;
                    /deep/ .el-cascader, /deep/.el-select {
                        width: 100%;
                    }
                    .main_form_list_item_center_margin {
                        margin:  0 -11px;
                        flex-wrap: wrap;
                        .main_form_sort_item {
                            height: 32px;
                            line-height: 32px;
                            padding: 0 8px;
                            margin: 0 11px;
                            cursor: pointer;
                        }
                        .main_form_sort_item_on {
                            background-color: #EDF4FF;
                            border-radius: 4px;
                            color: #1890FF;
                        }
                        .main_form_list_item_center_margin_item {
                            height: 32px;
                            line-height: 32px;
                            padding: 0 8px;
                            margin: 5px 13px;
                            cursor: pointer;
                            background-color: #F6F7FB;
                            color: #4E4E4E;
                        }
                        .main_form_list_item_center_margin_item:hover {
                            background-color: #EDF4FF;
                        }
                    }
                    > button {
                        width: 160px;
                    }
                }
            }
        }
    }
    .main_total {
        text-align: left;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        color: #212121;
        > strong {
            color: #EE1E1E;
        }
    }
    .main_table {
        text-align: left;
        border: 1px solid #EDF4FF;
        border-bottom: none;
        .main_table_item {
            border-bottom: 1px solid #EDF4FF;
            padding: 20px;
            cursor: pointer;
            .main_table_item_l {
                flex: 1;
                > p {
                    font-size: 16px;
                    font-weight: 500;
                }
                .main_table_item_l_time {
                    margin: 0 -10px;
                    > span {
                        display: inline-block;
                        padding: 0 10px;
                        font-size: 14px;
                        color: #4E4E4E;
                    }
                }
            }
            .main_table_item_r {
                padding-left: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #888888;
            }
        }
        .main_table_item:hover {
            color: #1890ff;
        }
    }
    .main_pagination {
        justify-content: flex-end;
        padding-top: 20px;
    }
</style>
